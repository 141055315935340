.pendo-resource-center-badge-notification-bubble {
  top: 5px !important;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer !important;
  position: absolute !important;
  left: -4px !important;
}

.pendo-notification-bubble-unread-count {
  font-size: 75% !important;
  right: -4px !important;
}

._pendo-resource-center-close-button {
  cursor: pointer !important;
}

._pendo-resource-center-left-caret {
  cursor: pointer !important;
}

._pendo-resource-center-right-caret {
  cursor: pointer !important;
}
